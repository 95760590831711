import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';
import { FiSun, FiMoon, FiMenu, FiX } from 'react-icons/fi';

const HeaderContainer = styled.header`
  position: fixed;
  width: 100%;
  padding: 20px 50px;
  background-color: ${({ theme }) => theme.body};
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  box-shadow: ${({ theme }) => theme.boxShadow};

  .logo {
    font-size: 24px;
    font-weight: bold;
    color: #8352FD;
  }

  .navbar {
    display: flex;
    align-items: center;

    a {
      margin-left: 20px;
      cursor: pointer;
      font-size: 16px;
      transition: color 0.3s;

      &:hover {
        color: #8352FD;
      }
    }
  }

  .theme-toggle {
    margin-left: 20px;
    cursor: pointer;
    font-size: 24px;
  }

  .mobile-menu-icon {
    display: none;
    cursor: pointer;
    font-size: 28px;
  }

  @media (max-width: 768px) {
    padding: 15px 20px;

    .navbar {
      display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
      position: absolute;
      top: 70px;
      left: 0;
      width: 100%;
      flex-direction: column;
      align-items: center;
      background-color: ${({ theme }) => theme.body};
      padding: 20px 0;
      box-shadow: ${({ theme }) => theme.boxShadow};

      a {
        margin-left: 0;
        margin-bottom: 20px;
      }
    }

    .mobile-menu-icon {
      display: block;
    }
  }
`;

function Header({ toggleTheme, theme }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <HeaderContainer isOpen={isOpen}>
      <a href="/" className="logo">Mohamed Abdelnaby</a>

      <div className="mobile-menu-icon" onClick={toggleMenu}>
        {isOpen ? <FiX /> : <FiMenu />}
      </div>

      <nav className="navbar">
        <Link to="home" smooth duration={500} onClick={toggleMenu}>Home</Link>
        <Link to="about" smooth duration={500} onClick={toggleMenu}>About</Link>
        <Link to="skills" smooth duration={500} onClick={toggleMenu}>Skills</Link>
        <Link to="projects" smooth duration={500} onClick={toggleMenu}>Projects</Link>
        <Link to="publications" smooth duration={500} onClick={toggleMenu}>Publications</Link>
        <Link to="contact" smooth duration={500} onClick={toggleMenu}>Contact</Link>
        <a href="https://mohamedhabdelnaby.medium.com/" target="_blank" rel="noopener noreferrer" onClick={toggleMenu}>Blog</a>
      </nav>

      <div className="theme-toggle" onClick={toggleTheme}>
        {theme === 'light' ? <FiMoon /> : <FiSun />}
      </div>
    </HeaderContainer>
  );
}

export default Header;