import React from 'react';
import styled from 'styled-components';
import { FaArrowUp } from 'react-icons/fa';

const FooterContainer = styled.footer`
  padding: 20px 50px;
  background-color: ${({ theme }) => theme.body};
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.text};

  p {
    font-size: 14px;
  }

  .footer-iconTop {
    a {
      font-size: 24px;
      color: ${({ theme }) => theme.text};
      transition: color 0.3s;

      &:hover {
        color: #8352FD;
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .footer-iconTop {
      margin-top: 10px;
    }
  }
`;

function Footer() {
  return (
    <FooterContainer>
      <p>© {new Date().getFullYear()} Website Designed and Developed by Mohamed Abdelnaby</p>
      <div className="footer-iconTop">
        <a href="#">
          <FaArrowUp />
        </a>
      </div>
    </FooterContainer>
  );
}

export default Footer;