import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyC8smpxSgDRJA5sO28BZWwP5IkSgd1iyCY",
  authDomain: "mohamedabdelnabydotcom.firebaseapp.com",
  projectId: "mohamedabdelnabydotcom",
  databaseURL: "https://mohamedabdelnabydotcom-default-rtdb.firebaseio.com",
  storageBucket: "mohamedabdelnabydotcom.appspot.com",
  messagingSenderId: "943578014838",
  appId: "1:943578014838:web:6ab120280b722062a76b57"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore and Database
export const firestore = getFirestore(app);
export const database = getDatabase(app);