import React, { useRef, useEffect, useState } from 'react';
import Globe from 'react-globe.gl';

function GlobeVisualization() {
  const globeEl = useRef();
  const [dimensions, setDimensions] = useState({ width: 400, height: 400 });

  // Define your locations
  const locations = {
    Cairo: { lat: 30.0444, lng: 31.2357 },
    Duino: { lat: 45.7734, lng: 13.6069 },
    Norman: { lat: 35.2226, lng: -97.4395 },
  };

  // Prepare the arcs data
  const arcsData = [
    {
      startLat: locations.Cairo.lat,
      startLng: locations.Cairo.lng,
      endLat: locations.Duino.lat,
      endLng: locations.Duino.lng,
      color: ['#FF6FD8', '#8352FD'],
      name: 'Cairo to Duino',
    },
    {
      startLat: locations.Duino.lat,
      startLng: locations.Duino.lng,
      endLat: locations.Norman.lat,
      endLng: locations.Norman.lng,
      color: ['#FF6FD8', '#8352FD'],
      name: 'Duino to Norman',
    },
    // Optional: Close the loop back to Cairo
    {
      startLat: locations.Norman.lat,
      startLng: locations.Norman.lng,
      endLat: locations.Cairo.lat,
      endLng: locations.Cairo.lng,
      color: ['#FF6FD8', '#8352FD'],
      name: 'Norman to Cairo',
    },
  ];

  // Prepare labels data for your locations
  const labelsData = [
    {
      lat: locations.Cairo.lat,
      lng: locations.Cairo.lng,
      text: 'Cairo',
    },
    {
      lat: locations.Duino.lat,
      lng: locations.Duino.lng,
      text: 'Duino',
    },
    {
      lat: locations.Norman.lat,
      lng: locations.Norman.lng,
      text: 'Norman, OK',
    },
  ];

  useEffect(() => {
    const globe = globeEl.current;

    // Adjust camera FOV
    globe.camera().fov = 45;
    globe.camera().updateProjectionMatrix();

    // Auto-rotate the globe
    globe.controls().autoRotate = true;
    globe.controls().autoRotateSpeed = 0.5;

    // Set minimum and maximum zoom distances
    globe.controls().minDistance = 200;
    globe.controls().maxDistance = 500;

    // Optional: Restrict vertical rotation
    globe.controls().minPolarAngle = 0;
    globe.controls().maxPolarAngle = Math.PI;

    // Set initial point of view
    globe.pointOfView({ lat: 30, lng: 0, altitude: 2 }, 1000);

    // Media query to handle screen size changes
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    
    // Update the dimensions based on screen size
    const handleResize = () => {
      if (mediaQuery.matches) {
        setDimensions({ width: 210, height: 210 });
      } else {
        setDimensions({ width: 400, height: 400 });
      }
    };

    // Set the initial dimensions
    handleResize();

    // Add an event listener for changes in the screen size
    mediaQuery.addEventListener('change', handleResize);

    // Clean up the event listener on component unmount
    return () => mediaQuery.removeEventListener('change', handleResize);
  }, []);

  return (
    <div className="globe-container">
      <Globe
        ref={globeEl}
        globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
        backgroundColor="rgba(0,0,0,0)"
        arcsData={arcsData}
        arcLabel={(d) => d.name}
        arcStartLat={(d) => d.startLat}
        arcStartLng={(d) => d.startLng}
        arcEndLat={(d) => d.endLat}
        arcEndLng={(d) => d.endLng}
        arcColor={(d) => d.color}
        arcDashLength={0.4}
        arcDashGap={0.2}
        arcDashAnimateTime={4000}
        labelsData={labelsData}
        labelLat={(d) => d.lat}
        labelLng={(d) => d.lng}
        labelText={(d) => d.text}
        labelSize={1.2}
        labelColor={() => '#FF6FD8'}
        width={dimensions.width}
        height={dimensions.height}
      />
    </div>
  );
}

export default GlobeVisualization;