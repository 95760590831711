import React from 'react';
import styled from 'styled-components';
import { FaCalendarAlt } from 'react-icons/fa';

const PublicationsSection = styled.section`
  padding: 80px 50px;
  background-color: ${({ theme }) => theme.sectionBg1};

  h2.heading {
    text-align: center;
    margin-bottom: 50px;
    color: ${({ theme }) => theme.text};
    font-size: 36px;
    font-weight: bold;
    position: relative;
    display: inline-block;

    span {
      background: linear-gradient(90deg, #8352FD, #FF6FD8);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -10px;
      width: 100px;
      height: 4px;
      background-color: #8352FD;
      animation: slideIn 1s ease forwards;
    }
  }

  .publications-row {
    display: flex;
    justify-content: center;
  }

  .publications-column {
    width: 80%;
  }

  .publication-content {
    background-color: ${({ theme }) => theme.card};
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 12px;
    box-shadow: ${({ theme }) => theme.boxShadow};
    color: ${({ theme }) => theme.text};

    .content {
      .year {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-bottom: 10px;

        i {
          margin-right: 5px;
        }
      }

      p {
        font-size: 16px;
        line-height: 1.6;
      }

      strong {
        color: #8352FD;
      }
    }
  }

  @keyframes slideIn {
    0% {
      width: 0;
    }
    100% {
      width: 100px;
    }
  }
`;

function Publications() {
    const publications = [
      {
        year: '2024',
        authors: 'Mohamed Abdelnaby and Marmar Moussa',
        title: 'Benchmarking Principal Component and Random Projection dimensionality reduction methods for single cell RNA sequencing data',
        event: '2024 International Conference on Intelligent Systems for Molecular Biology (ISMB); July 2024; Montreal, Quebec, Canada.',
      },
      {
        year: '2024',
        authors: 'Mohamed Abdelnaby et al.',
        title: 'Enhancing Inclusivity in Education through XR and AR with Cognitive-Affective Learning Principles for Students with Special Needs',
        event: '2024 ACM Conference on Human Factors in Computing Systems (CHI); May 2024; Honolulu, Hawaii, USA.',
      },
      {
        year: '2024',
        authors: 'Mohamed Abdelnaby and Marmar Moussa',
        title: 'Random Projections techniques for locality-preserving representation of high-dimensional single cell RNA-seq',
        event: '2024 International Conference on Research in Computational Molecular Biology (RECOMB); April 2024; Cambridge, MA, USA.',
      },
      {
        year: '2024',
        authors: 'Mohamed Abdelnaby et al.',
        title: 'Integrating Accelerometers & Neural Networks for Enhanced Soccer Analytics',
        event: '2024 University of Oklahoma – Tulsa REACH-OUT Poster Forum; April 2024; Tulsa, OK, USA.',
      },
    ];

  return (
    <PublicationsSection id="publications">
      <h2 className="heading">
        My <span>Publications</span>
      </h2>
      <div className="publications-row">
        <div className="publications-column">
          {publications.map((pub, index) => (
            <div className="publication-content" key={index}>
              <div className="content">
                <div className="year">
                  <FaCalendarAlt />
                  {pub.year}
                </div>
                <p>
                  <strong>{pub.authors}</strong>. "{pub.title}". {pub.event}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </PublicationsSection>
  );
}

export default Publications;
