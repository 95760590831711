import React, { useRef, useMemo, useState } from 'react';
import { useFrame } from '@react-three/fiber';
import { OrbitControls, Line, Sphere, Text, PerspectiveCamera } from '@react-three/drei';
import * as THREE from 'three';

function NeuralNetwork() {
  const groupRef = useRef();
  const isMobile = window.innerWidth < 768;
  
  // Define the network structure: [Input, Hidden1, Hidden2, Output]
  const networkStructure = [5, 4, 4, 3]; 

  // Generate neuron positions
  const layers = useMemo(() => {
    return networkStructure.map((numNeurons, layerIndex) => {
      const positions = [];
      const x = layerIndex * 3; // Spacing between layers

      for (let i = 0; i < numNeurons; i++) {
        const y = ((i - (numNeurons - 1) / 2) * 1.5); // Spread neurons along y-axis
        const z = 0; // Keep z constant for a 2D layer layout
        positions.push(new THREE.Vector3(x, y, z));
      }
      return positions;
    });
  }, [networkStructure]);

  // Prepare connections between neurons
  const connections = useMemo(() => {
    const conns = [];
    for (let i = 0; i < layers.length - 1; i++) {
      const currentLayer = layers[i];
      const nextLayer = layers[i + 1];

      currentLayer.forEach((currentNeuron) => {
        nextLayer.forEach((nextNeuron) => {
          conns.push([currentNeuron, nextNeuron]);
        });
      });
    }
    return conns;
  }, [layers]);

  // State to manage hovered neuron
  const [hoveredNeuron, setHoveredNeuron] = useState(null);
  const [activeNeuron, setActiveNeuron] = useState(null);

  // Animation: Rotate the network
  useFrame(() => {
    if (groupRef.current) {
      groupRef.current.rotation.y += 0.001; // Slow rotation for better visualization
    }
  });

  return (
    <>
      {/* Add a Perspective Camera for a better view */}
      <PerspectiveCamera makeDefault position={[10, 5, 10]} fov={50}>
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 10]} intensity={1} />
        <OrbitControls enableZoom={true} enablePan={true} enableRotate={true} />
      </PerspectiveCamera>

      <group ref={groupRef}>
        {/* Render Connections */}
        {connections.map((connection, index) => (
          <Line
            key={`line-${index}`}
            points={connection}
            color={activeNeuron && connection.some(neuron => neuron.equals(activeNeuron)) ? '#FF5733' : '#FFFFFF'}
            lineWidth={1}
            transparent
            opacity={activeNeuron && connection.some(neuron => neuron.equals(activeNeuron)) ? 0.7 : 0.3}
          />
        ))}

        {/* Render Neurons */}
        {layers.map((layer, layerIndex) =>
          layer.map((position, neuronIndex) => (
            <Sphere
              key={`neuron-${layerIndex}-${neuronIndex}`}
              args={[0.25, 32, 32]}
              position={position}
              onPointerOver={(e) => {
                e.stopPropagation();
                setHoveredNeuron(`Layer ${layerIndex + 1}, Neuron ${neuronIndex + 1}`);
              }}
              onPointerOut={(e) => {
                e.stopPropagation();
                setHoveredNeuron(null);
              }}
              onClick={(e) => {
                e.stopPropagation();
                setActiveNeuron(position);
                setTimeout(() => setActiveNeuron(null), 1500); // Deactivate after 500ms
              }}
            >
              <meshStandardMaterial
                color={hoveredNeuron === `Layer ${layerIndex + 1}, Neuron ${neuronIndex + 1}` ? '#FF5733' : '#FFD700'}
                emissive={hoveredNeuron === `Layer ${layerIndex + 1}, Neuron ${neuronIndex + 1}` ? '#FF5733' : '#FFFF00'}
                emissiveIntensity={hoveredNeuron === `Layer ${layerIndex + 1}, Neuron ${neuronIndex + 1}` ? 1 : 0.5}
              />
              {/* Add Labels */}
              <Text
                position={[0, 0.3, 0]}
                fontSize={0.3}
                color="#FFFFFF"
                anchorX="center"
                anchorY="middle"
                visible={hoveredNeuron === `Layer ${layerIndex + 1}, Neuron ${neuronIndex + 1}`}
              >
                {`L${layerIndex + 1}-N${neuronIndex + 1}`}
              </Text>
            </Sphere>
          ))
        )}
      </group>
    </>
  );
}

export default NeuralNetwork;