import React, { useState, useRef } from 'react';
import styled from 'styled-components'; 
import emailjs from 'emailjs-com';
import { firestore } from '../firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore'; // Add collection and addDoc

const ContactSection = styled.section`
  padding: 80px 50px;
  background-color: ${({ theme }) => theme.sectionBg2};

  h2.heading {
    text-align: center;
    margin-bottom: 50px;
    color: ${({ theme }) => theme.text};
    font-size: 36px;
    font-weight: bold;
    position: relative;
    display: inline-block;

    span {
      background: linear-gradient(90deg, #8352FD, #FF6FD8);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -10px;
      width: 100px;
      height: 4px;
      background-color: #8352FD;
      animation: slideIn 1s ease forwards;
    }
  }

  form {
    max-width: 600px;
    margin: 0 auto;

    .input-box {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .input-field {
        width: 48%;
        position: relative;

        input {
          width: 100%;
          padding: 10px 15px;
          font-size: 16px;
          border: 1px solid #ccc;
          border-radius: 5px;
          outline: none;
          background: transparent;
          color: ${({ theme }) => theme.text};
        }

        span.focus {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0%;
          height: 2px;
          background-color: #8352FD;
          transition: 0.4s;
        }

        input:focus ~ span.focus {
          width: 100%;
        }
      }
    }

    .textarea-field {
      position: relative;
      margin-bottom: 20px;

      textarea {
        width: 100%;
        padding: 10px 15px;
        font-size: 16px;
        height: 150px;
        border: 1px solid #ccc;
        border-radius: 5px;
        outline: none;
        background: transparent;
        color: ${({ theme }) => theme.text};
        resize: none;
      }

      span.focus {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 2px;
        background-color: #8352FD;
        transition: 0.4s;
      }

      textarea:focus ~ span.focus {
        width: 100%;
      }
    }

    .btn-box {
      text-align: center;

      button {
        padding: 12px 25px;
        background-color: #8352FD;
        color: #FFFFFF;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;

        &:hover {
          background-color: #6C3CE6;
        }
      }

      p {
        margin-top: 15px;
        font-size: 16px;
        color: ${({ theme }) => theme.text};
      }
    }
  }

  @keyframes slideIn {
    0% {
      width: 0;
    }
    100% {
      width: 100px;
    }
  }
`;

function Contact() {
    const [formStatus, setFormStatus] = useState('');
    const form = useRef(null);
  
    const handleSubmit = async (e) => {
      e.preventDefault();

          // Ensure form.current is a valid reference to the form element
    if (!form.current) {
      console.error("Form reference is invalid.");
      return;
    }
    
      const formData = {
        name: e.target.client_name.value,
        email: e.target.client_email.value,
        mobile: e.target.client_mobile.value || null,
        subject: e.target.email_subject.value,
        message: e.target.client_message.value,
      };
    
      try {
        await addDoc(collection(firestore, "contacts"), {
          ...formData,
          timestamp: serverTimestamp()
        });
        setFormStatus('✅ Thank you! Your message has been sent.');
      } catch (error) {
        console.error("Error adding document: ", error);
        setFormStatus('❌ Failed to send message.');
      }
    
    console.log("Form reference (form.current):", form.current);
      
    // EmailJS functionality
    emailjs
      .sendForm(
        'service_basw4ob', 
        'template_8yda9ts', 
        form.current,
        'MhNSdbp4dhXhoYT67', 
      )
      .then(
        () => {
          setFormStatus('✅ Email sent successfully!');
        },
        (error) => {
          console.error('FAILED...', error.text);
          setFormStatus('❌ Failed to send email.');
        },
      );
  };
  
    return (
      <ContactSection id="contact">
        <h2 className="heading">
          Contact <span>Me</span>
        </h2>
        <form ref={form} onSubmit={handleSubmit} id="contactForm">
          <div className="input-box">
            <div className="input-field">
              <input type="text" name="client_name" placeholder="Full Name" required />
              <span className="focus"></span>
            </div>
            <div className="input-field">
              <input type="email" name="client_email" placeholder="Email Address" required />
              <span className="focus"></span>
            </div>
          </div>
          <div className="input-box">
            <div className="input-field">
              <input type="text" name="client_mobile" placeholder="Mobile Number (optional)" />
              <span className="focus"></span>
            </div>
            <div className="input-field">
              <input type="text" name="email_subject" placeholder="Email Subject" required />
              <span className="focus"></span>
            </div>
          </div>
          <div className="textarea-field">
            <textarea name="client_message" placeholder="Your Message" required></textarea>
            <span className="focus"></span>
          </div>
          <div className="btn-box">
              <button type="submit" id="submit">Submit</button>
          </div>
          {formStatus && <div id="form-message">{formStatus}</div>}
        </form>
      </ContactSection>
    );
  }
  
  export default Contact;
