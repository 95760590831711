import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from './styles/theme';
import GlobalStyles from './styles/GlobalStyles';
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Skills from './components/Skills';
import Projects from './components/Projects';
import Publications from './components/Publications';
import Contact from './components/Contact';
import Footer from './components/Footer';
import NeuralNetworkCanvas from './components/NeuralNetworkCanvas';
import { Canvas } from '@react-three/fiber';

function App() {
  const [theme, setTheme] = useState('dark');

  const themeMode = theme === 'light' ? lightTheme : darkTheme;

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  return (
    <ThemeProvider theme={themeMode}>
      <GlobalStyles />
      <Header toggleTheme={toggleTheme} theme={theme} />
      <main>
        <Home />
        <About />
        <Skills />
        <Projects />
        <Publications />
        <Contact />
        
      </main>
      <Footer />
    </ThemeProvider>
  );
}

export default App;