import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from './Modal';
import { FaGithub, FaExternalLinkAlt, FaCalendarAlt } from 'react-icons/fa';

const ProjectsSection = styled.section`
  padding: 100px 50px;
  background-color: ${({ theme }) => theme.sectionBg2};
  position: relative;
  overflow: hidden;

    h2.heading {
      font-size: 48px;
      margin-bottom: 20px;
      font-weight: bold;
      position: relative;
      display: inline-block;

      span {
        background: linear-gradient(90deg, #8352FD, #FF6FD8);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -5px;
        width: 100%;
        height: 4px;
        background-color: #8352FD;
        animation: slideIn 1s ease forwards;
      }
    }


    
    span {
      background: linear-gradient(90deg, #8352FD, #FF6FD8);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -20px;
      width: 150px;
      height: 5px;
      background: linear-gradient(90deg, #8352FD, #FF6FD8);
      box-shadow: 0px 0px 20px rgba(131, 82, 253, 0.7);
      animation: slideIn 1s ease forwards;
    }
  }

  .projects-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    perspective: 1000px;
  }

  .projects-column {
    width: 48%;
    margin-bottom: 50px;
    transform-style: preserve-3d;
    perspective: 1000px;

    h3.title {
      font-size: 30px;
      margin-bottom: 40px;
      color: ${({ theme }) => theme.text};

      span {
        color: #8352FD;
      }
    }

    .projects-box {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .projects-content {
    background-color: ${({ theme }) => theme.card};
    padding: 30px;
    margin-bottom: 20px;
    border-radius: 20px;
    box-shadow: ${({ theme }) => theme.boxShadow};
    transition: transform 0.5s, box-shadow 0.5s;
    transform: rotateY(0deg) rotateX(0deg) translateZ(0);
    will-change: transform;
    cursor: pointer;

    &:hover {
      transform: rotateY(10deg) rotateX(5deg) translateZ(10px);
      box-shadow: 0px 15px 35px rgba(0, 0, 0, 0.2);
    }

    .content {
      .year {
        display: flex;
        align-items: center;
        font-size: 16px;
        margin-bottom: 10px;

        i {
          margin-right: 5px;
        }
      }

      h3 {
        font-size: 24px;
        margin-bottom: 10px;
        color: #8352FD;
        transition: color 0.3s ease;
      }

      p {
        font-size: 16px;
        margin-bottom: 15px;
      }

      .project-links {
        display: flex;
        align-items: center;

        button,
        a {
          margin-right: 15px;
          padding: 10px 20px;
          background-color: #8352FD;
          color: #FFFFFF;
          border: none;
          border-radius: 50px;
          cursor: pointer;
          font-size: 14px;
          display: flex;
          align-items: center;
          text-decoration: none;
          transition: background-color 0.3s ease, transform 0.3s ease;

          i {
            margin-right: 5px;
          }

          &:hover {
            background-color: #6C3CE6;
            transform: translateY(-5px);
          }
        }
      }
    }
  }

  @keyframes slideIn {
    0% {
      width: 0;
    }
    100% {
      width: 150px;
    }
  }
`;

function Projects() {
  const [selectedProject, setSelectedProject] = useState(null);

  const researchProjects = [
    {
      id: 1,
      year: '2022',
      title: 'Data Science: Urban Scaling Project',
      description: 'Over 500,000 Latin inscriptions were web-scraped, cleaned, and analyzed to map Urban Scaling across the Roman Empire.',
      github: 'https://github.com/OU-DISC/urban_scaling',
      modalContent: `
        <p>Overview of the project:</p>
        <ul>
          <li>Scraped over 500,000 Latin inscriptions and appended them to a database.</li>
          <li>Cleaned and analyzed the data using Pandas and other data manipulation techniques.</li>
          <li>Merged the dataset with additional information about provinces and cities.</li>
          <li>Utilized the CLTK library for Latin Lemmatization and Synonymization.</li>
          <li>Applied clustering techniques, including K-means, DBSCAN, Decision Trees, and more.</li>
          <li>Performed linear regression for Urban Scaling predictions.</li>
        </ul>
      `
    },
    {
      id: 2,
      year: '2022',
      title: 'Developing Adversarial Machine Learning Algorithms',
      description: 'Exploration and development of advanced adversarial machine learning algorithms and techniques for detection.',
      github: 'https://github.com/MohamedHAbdelnaby/Adversarial_ML',
      modalContent: `
        <p>Overview of the project:</p>
        <ul>
          <li>Investigated existing adversarial algorithms such as FGSM, DeepFool, C&W, PGD, and BIM.</li>
          <li>Evaluated attacks on models such as Linear Regression, Decision Trees, and Random Forest.</li>
          <li>Developed techniques to improve detection of adversarial attacks.</li>
        </ul>
      `
    },
    {
      id: 3,
      year: '2021',
      title: 'Intrusion Detection using Machine Learning',
      description: 'Used machine learning to develop a network intrusion detection system (IDS).',
      github: 'https://github.com/MohamedHAbdelnaby/ML-Network_IDS',
      researchgate: 'https://www.researchgate.net/publication/359504667_RSA_algorithm_in_P_vs_NP_domain_and_time_complexity',
      modalContent: `
        <p>Key highlights:</p>
        <ul>
          <li>Collected and preprocessed network logs for training ML models.</li>
          <li>Utilized algorithms such as Naive Bayes, Decision Trees, and Random Forest to classify network traffic.</li>
          <li>Achieved high detection rates, enhancing network threat detection.</li>
        </ul>
      `
    },
    {
      id: 4,
      year: '2019',
      title: 'RSA Algorithm Research Project',
      description: 'Investigated the RSA cryptography algorithm, focusing on key generation, encryption, and quantum computing challenges.',
      github: 'https://github.com/MohamedHAbdelnaby/RSA-Implementation/',
      researchgate: 'https://www.researchgate.net/publication/359504667_RSA_algorithm_in_P_vs_NP_domain_and_time_complexity',
      modalContent: `
        <p>Project details:</p>
        <ul>
          <li>Focused on key generation, encryption, and decryption using Python.</li>
          <li>Explored potential threats from advancements in quantum computing on RSA encryption.</li>
        </ul>
      `
    }
  ];

  const softwareProjects = [
    {
      id: 5,
      year: '2023',
      title: 'AutoGuardian: Car Maintenance React Native App with Supabase',
      description: 'A mobile app for car maintenance tracking built with React Native and Supabase.',
      github: 'https://github.com/MohamedHAbdelnaby/HackUTA',
      modalContent: `
        <p>Key features of the app:</p>
        <ul>
          <li>User authentication using Supabase.</li>
          <li>Maintenance scheduling and logging features.</li>
          <li>Integration of a GPT-3 chatbot for car maintenance queries.</li>
        </ul>
      `
    },
    {
      id: 6,
      year: '2023',
      title: 'Advanced AI Chatbot for IT Services',
      description: 'A niche-specific chatbot for IT support services using GPT-3 and custom knowledge indexing.',
      github: 'https://github.com/MohamedHAbdelnaby/itsupport_ai',
      modalContent: `
        <p>Key features:</p>
        <ul>
          <li>Utilizes over 380 IT support articles to provide accurate responses.</li>
          <li>Hosted on AWS with a FastAPI backend and integrated with GPT-3 for advanced query handling.</li>
          <li>Deployed on AWS EC2 with seamless frontend-backend integration.</li>
        </ul>
      `
    },
    {
      id: 7,
      year: '2023',
      title: 'Hacklahoma Website & Registration System',
      description: 'Developed the Hacklahoma 2024 website and registration system with secure user authentication.',
      github: 'https://github.com/MohamedHAbdelnaby/itsupport_ai',
      website: 'https://2024.hacklahoma.org',
      modalContent: `
        <p>Key features:</p>
        <ul>
          <li>Built using PostgreSQL to securely handle participant registration.</li>
          <li>The website serves as the central hub for Hacklahoma 2024.</li>
        </ul>
      `
    }
  ];

  const openModal = (project) => {
    setSelectedProject(project);
  };

  const closeModal = () => {
    setSelectedProject(null);
  };

  return (
    <ProjectsSection id="projects">
      <h2 className="heading">
        My <span>Projects</span>
      </h2>
      <div className="projects-row">
        {/* Research Projects */}
        <div className="projects-column">
          <h3 className="title">
            <span>Research</span> Projects
          </h3>
          <div className="projects-box">
            {researchProjects.map((project) => (
              <div className="projects-content" key={project.id} onClick={() => openModal(project)}>
                <div className="content">
                  <div className="year">
                    <FaCalendarAlt />
                    {project.year}
                  </div>
                  <h3>{project.title}</h3>
                  <p>{project.description}</p>
                  <div className="project-links">
                    <button>
                      Read More
                    </button>
                    <a href={project.github} target="_blank" rel="noopener noreferrer">
                      <FaGithub />
                      Github
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* Software Projects */}
        <div className="projects-column">
          <h3 className="title">
            <span>Software</span> Projects
          </h3>
          <div className="projects-box">
            {softwareProjects.map((project) => (
              <div className="projects-content" key={project.id} onClick={() => openModal(project)}>
                <div className="content">
                  <div className="year">
                    <FaCalendarAlt />
                    {project.year}
                  </div>
                  <h3>{project.title}</h3>
                  <p>{project.description}</p>
                  <div className="project-links">
                    <button>
                      Read More
                    </button>
                    <a href={project.github} target="_blank" rel="noopener noreferrer">
                      <FaGithub />
                      Github
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Modals */}
      {selectedProject && (
        <Modal project={selectedProject} closeModal={closeModal} />
      )}
    </ProjectsSection>
  );
}

export default Projects;