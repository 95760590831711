import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaTimes, FaGithub, FaExternalLinkAlt } from 'react-icons/fa';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
`;

const ModalContent = styled(motion.div)`
  background-color: ${({ theme }) => theme.card};
  padding: 30px;
  width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  border-radius: 12px;
  position: relative;
  box-shadow: ${({ theme }) => theme.boxShadow};
  color: ${({ theme }) => theme.text};

  h2 {
    margin-bottom: 20px;
    color: #8352FD;
  }

  p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 15px;
  }

  /* Adding global styles for ul and li inside modal */
  ul {
    margin-bottom: 15px;
    padding-left: 20px;
    list-style-type: disc; /* Force bullets to appear */
  }

  li {
    margin-bottom: 10px;
    font-size: 16px;
    list-style-position: inside; /* Ensure bullets are inside the padding */
  }

  .modal-links {
    margin-top: 20px;

    a {
      margin-right: 15px;
      padding: 8px 15px;
      background-color: #8352FD;
      color: #FFFFFF;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
      text-decoration: none;

      &:hover {
        background-color: #6C3CE6;
      }
    }
  }

  .close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 24px;
    cursor: pointer;
    background: none;
    border: none;
    color: ${({ theme }) => theme.text};
  }
`;

function Modal({ project, closeModal }) {
    return (
      <ModalOverlay onClick={closeModal}>
        <ModalContent
          onClick={(e) => e.stopPropagation()}
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
        >
          <button className="close-button" onClick={closeModal}>
            <FaTimes />
          </button>
          <h2>{project.title}</h2>
          {/* Use dangerouslySetInnerHTML to render HTML content */}
          <div dangerouslySetInnerHTML={{ __html: project.modalContent }} />
          <div className="modal-links">
            {project.github && (
              <a href={project.github} target="_blank" rel="noopener noreferrer">
                <FaGithub /> Github
              </a>
            )}
            {project.liveDemo && (
              <a href={project.liveDemo} target="_blank" rel="noopener noreferrer">
                <FaExternalLinkAlt /> Live Demo
              </a>
            )}
          </div>
        </ModalContent>
      </ModalOverlay>
    );
  }

export default Modal;