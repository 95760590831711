import React from 'react';
import { Canvas } from '@react-three/fiber';
import styled from 'styled-components';
import NeuralNetwork from './NeuralNetwork';

const CanvasContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;

function ThreeCanvas() {
  return (
    <CanvasContainer>
      <Canvas camera={{ position: [0, 0, 5] }}>
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 10]} />
        <NeuralNetwork />
      </Canvas>
    </CanvasContainer>
  );
}

export default ThreeCanvas;