import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import MohamedImage from '../assets/images/Mohamed.png';
import GlobeVisualization from './GlobeVisualization';

const AboutSection = styled.section`
  padding: 100px 50px;
  background-color: ${({ theme }) => theme.sectionBg2};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .about-content {
    flex: 1;
    color: ${({ theme }) => theme.text};
    max-width: 600px;

    h2.heading {
      font-size: 48px;
      margin-bottom: 20px;
      font-weight: bold;
      position: relative;
      display: inline-block;

      span {
        background: linear-gradient(90deg, #8352FD, #FF6FD8);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -5px;
        width: 100%;
        height: 4px;
        background-color: #8352FD;
        animation: slideIn 1s ease forwards;
      }
    }

    h3 {
      font-size: 28px;
      margin-bottom: 20px;
      color: #8352FD;
      font-weight: 500;
    }

    p {
      font-size: 18px;
      line-height: 1.8;
      margin-bottom: 20px;
      letter-spacing: 0.5px;
    }

    @keyframes slideIn {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }
  }

  .about-img {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    perspective: 1000px;

    .img-globe-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      
      @media (max-width: 768px) {
        flex-direction: column;
      }
    }

    img {
      width: 300px;
      height: 300px;
      border-radius: 50%;
      border: 5px solid #8352FD;
      box-shadow: 0 0 40px rgba(255, 111, 216, 0.8), 0 0 60px rgba(131, 82, 253, 0.8);
      animation: float 6s ease-in-out infinite;
      transition: transform 0.5s ease, filter 0.5s ease;
      margin: 0 20px;
    }

    img:hover {
      transform: scale(1.1) rotate(10deg);
      filter: brightness(1.2);
    }

    .globe-container {
      width: 300px;
      height: 300px;
      transition: width 0.3s, height 0.3s;

      @media (max-width: 1024px) {
        width: 250px;
        height: 250px;
      }

      @media (max-width: 768px) {
        width: 180px;
        height: 180px;
      }

      @media (max-width: 480px) {
        width: 120px;
        height: 120px;
      }
    }

    .neon-glow {
      position: absolute;
      width: 650px;
      height: 350px;
      border-radius: 50%;
      background: radial-gradient(circle, rgba(131, 82, 253, 0.3), rgba(255, 111, 216, 0.3));
      filter: blur(40px);
      animation: pulse 2.5s infinite alternate;
      z-index: -1;

      @media (max-width: 1024px) {
        width: 500px;
        height: 300px;
      }

      @media (max-width: 768px) {
        width: 300px;
        height: 200px;
      }

      @media (max-width: 480px) {
        width: 200px;
        height: 150px;
      }
    }

    @keyframes float {
      0%, 100% {
        transform: translateY(-10px);
      }
      50% {
        transform: translateY(10px);
      }
    }

    @keyframes pulse {
      0% {
        opacity: 0.8;
      }
      100% {
        opacity: 1;
      }
    }
  }

@media (max-width: 1024px) {
  flex-direction: column;
  padding: 50px 20px; 

  .about-img {
    margin-bottom: 50px;
  }

  .about-content {
    text-align: center;
    h2.heading {
      font-size: 36px; 
    }

    h3 {
      font-size: 24px; 
    }

    p {
      font-size: 16px; 
      text-align: justify;
    }
  }

  .about-img img {
    width: 250px; 
    height: 250px;
  }
}

@media (max-width: 768px) {
  .about-content {
    h2.heading {
      font-size: 32px; 
    }

    h3 {
      font-size: 22px;
    }

    p {
      font-size: 14px;
    }
  }

  .about-img img {
    width: 200px; 
    height: 200px;
  }

  .neon-glow {
    width: 250px; 
    height: 200px;
  }
}
`;


function About() {
  return (
    <AboutSection id="about">
      <motion.div
        className="about-content"
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
      >
        <h2 className="heading">
          About <span>Me</span>
        </h2>
        <motion.h3
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.8 }}
        >
          Software Engineer/Machine Learning Researcher
        </motion.h3>
        <motion.p
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4, duration: 0.8 }}
        >
          Growing up in Cairo, Egypt, I was awarded a full scholarship in 2018 to study at UWC Adriatic in Trieste, Italy. After completing the International Baccalaureate diploma, I received another full scholarship to study Computer Engineering at the University of Oklahoma.
        </motion.p>
        <motion.p
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6, duration: 0.8 }}
        >
          While at the University of Oklahoma, I received various research fellowships and scholarships, enabling me to work on Machine Learning, Adversarial Machine Learning, and Data Science research projects. My participation in Hackathons and student organizations added to my academic experience.
        </motion.p>
        <motion.p
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.8, duration: 0.8 }}
        >
          I'm currently enrolled in an accelerated Master of Science program in Computer Science with a focus on AI/ML at the University of Oklahoma, and I'm expected to complete it in December 2024. I've been involved in campus activities and organizations such as Hacklahoma and OU AI, further enriching my experiences.
        </motion.p>
      </motion.div>

      <motion.div
      className="about-img"
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ delay: 1, duration: 1, ease: "easeInOut" }}
    >
      <div className="img-globe-container">
        <img src={MohamedImage} alt="Mohamed Abdelnaby" />
        <div className="globe-container">
          <GlobeVisualization />
        </div>
      </div>
      <div className="neon-glow"></div>
    </motion.div>
    </AboutSection>
  );
}

export default About;
