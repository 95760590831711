import React, { useState, useRef, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, Stars, Html } from '@react-three/drei';
import { useMediaQuery } from 'react-responsive';
import {
  FaCode,
  FaServer,
  FaDatabase,
  FaBrain,
  FaHtml5,
  FaCss3Alt,
  FaJs,
  FaReact,
  FaNodeJs,
  FaDocker,
  FaAws,
} from 'react-icons/fa';
import {
  SiMongodb,
  SiPostgresql,
  SiMysql,
  SiTensorflow,
  SiPytorch,
  SiScikitlearn,
  SiPandas,
  SiNumpy,
} from 'react-icons/si';

const SkillsSection = styled.section`
  padding: 80px 50px;
  background-color: ${({ theme }) => theme.sectionBg1};
  position: relative;
  min-height: 100vh;
  overflow: hidden;

  h2.heading,
  p {
    position: relative;
    z-index: 2; /* Ensure text is above the Canvas */
  }

  h2.heading {
    text-align: center;
    margin-bottom: 50px;
    color: ${({ theme }) => theme.text};
    font-size: 42px;
    font-weight: bold;
    position: relative;
    display: inline-block;

    span {
      background: linear-gradient(90deg, #8352fd, #ff6fd8);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -10px;
      width: 100px;
      height: 4px;
      background-color: #8352fd;
      animation: slideIn 1s ease forwards;
    }
  }

  p {
    text-align: center;
    font-size: 20px;
    margin-bottom: 50px;
    color: ${({ theme }) => theme.text};
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }

  /* Media queries for mobile */
  @media (max-width: 768px) {
    padding: 60px 20px;
    min-height: auto;

    h2.heading {
      font-size: 24px;
      margin-top: -10px;
      margin-bottom: 430px; 
    }

    p {
      font-size: 18px;
    }
  }
`;

/* Styled Canvas component */
const StyledCanvas = styled(Canvas)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Place Canvas below text */
`;

const SkillIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;

  .icon {
    font-size: 90px;
    color: ${({ theme }) => theme.text};
    transition: color 0.3s ease;

    &:hover {
      color: #ffd700;
    }
  }

  .title {
    font-size: 28px;
    margin-top: 20px;
    background: linear-gradient(90deg, #8352fd, #ff6fd8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    white-space: nowrap;
  }

  .subskills {
    display: ${({ isExpanded }) => (isExpanded ? 'grid' : 'none')};
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 20px;
    transition: opacity 0.5s ease;
    opacity: ${({ isExpanded }) => (isExpanded ? 1 : 0)};
  }

  .subskill-item {
    background: rgba(255, 255, 255, 0.1);
    padding: 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    color: #ffffff;
    font-size: 16px;

    .subskill-icon {
      margin-right: 8px;
      font-size: 20px;
    }
  }

  /* Media queries for mobile */
  @media (max-width: 768px) {
    .icon {
      font-size: 70px; /* Slightly larger for better visibility */
    }

    .title {
      font-size: 22px;
      margin-top: 15px; 
    }

    .subskill-item {
      font-size: 14px;
    }

    .subskills {
      grid-template-columns: repeat(2, 1fr);
    }

    margin-bottom: 40px; /* Add spacing between skill icons */
  }
`;

const expandAnimation = keyframes`
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
`;

const AnimatedSkillIconContainer = styled(SkillIconContainer)`
  .subskills {
    animation: ${({ isExpanded }) =>
      isExpanded
        ? css`
            ${expandAnimation} 0.5s ease forwards
          `
        : 'none'};
    transform-origin: top;
  }
`;

function MovingStars({ theme }) {
  const starsRef = useRef();

  useFrame(({ clock }) => {
    const elapsed = clock.getElapsedTime();
    if (starsRef.current) {
      starsRef.current.rotation.x = elapsed * 0.05;
      starsRef.current.rotation.y = elapsed * 0.05;
    }
  });

  return (
    <Stars
      ref={starsRef}
      radius={100}
      depth={50}
      count={5000}
      factor={4}
      fade
      saturation={0}
      speed={1}
      color={theme === 'light' ? '#666' : '#ffffff'}
    />
  );
}

function SkillIcon({
  icon,
  title,
  position,
  highlighted,
  subskills,
  isExpanded,
  onClick,
}) {
  return (
    <mesh position={position}>
      <Html position={[0, 1, 0]} transform>
        <AnimatedSkillIconContainer isExpanded={isExpanded} onClick={onClick}>
          <div className="icon">{icon}</div>
          <div className="title">{title}</div>
          <div className="subskills">
            {subskills.map((subskill, index) => (
              <div key={index} className="subskill-item">
                <div className="subskill-icon">{subskill.icon}</div>
                <div>{subskill.name}</div>
              </div>
            ))}
          </div>
        </AnimatedSkillIconContainer>
      </Html>
    </mesh>
  );
}

function Skills({ theme }) {
  const [expandedSkillIndex, setExpandedSkillIndex] = useState(null);

  // Detect if the device is mobile
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const skills = [
    {
      icon: <FaDatabase />,
      title: 'Databases',
      subskills: [
        { name: 'MySQL', icon: <SiMysql /> },
        { name: 'PostgreSQL', icon: <SiPostgresql /> },
        { name: 'MongoDB', icon: <SiMongodb /> },
        { name: 'MariaDB', icon: <SiMysql /> },
      ],
      position: [-15, 0, -10],
    },
    {
      icon: <FaCode />,
      title: 'Front-End Development',
      subskills: [
        { name: 'HTML5', icon: <FaHtml5 /> },
        { name: 'CSS3', icon: <FaCss3Alt /> },
        { name: 'JavaScript', icon: <FaJs /> },
        { name: 'React.js', icon: <FaReact /> },
      ],
      position: [-5, 0, -10],
    },
    {
      icon: <FaServer />,
      title: 'Back-End Development',
      subskills: [
        { name: 'Node.js', icon: <FaNodeJs /> },
        { name: 'Express.js', icon: <FaNodeJs /> },
        { name: 'Docker', icon: <FaDocker /> },
        { name: 'AWS', icon: <FaAws /> },
      ],
      position: [5, 0, -10],
    },
    {
      icon: <FaBrain />,
      title: 'Machine Learning',
      subskills: [
        { name: 'TensorFlow', icon: <SiTensorflow /> },
        { name: 'PyTorch', icon: <SiPytorch /> },
        { name: 'Scikit-learn', icon: <SiScikitlearn /> },
        { name: 'Pandas', icon: <SiPandas /> },
        { name: 'NumPy', icon: <SiNumpy /> },
      ],
      position: [15, 0, -10],
    },
  ];

  // Automatic sequential expansion (only on non-mobile devices)
  useEffect(() => {
    if (!isMobile) {
      let currentIndex = 0;
      const interval = setInterval(() => {
        setExpandedSkillIndex(currentIndex);
        currentIndex = (currentIndex + 1) % skills.length;
      }, 3000); // Change skill every 3 seconds

      return () => clearInterval(interval);
    } else {
      setExpandedSkillIndex(null); // No skill is highlighted on mobile
    }
  }, [skills.length, isMobile]);

  // Adjust positions based on screen size
  const adjustedSkills = skills.map((skill, index) => {
    if (isMobile) {
      // Arrange in a 2x2 grid on mobile devices
      const row = Math.floor(index / 2);
      const col = index % 2;
      return {
        ...skill,
        position: [
          col === 0 ? -3 : 3.5, // x position
          row === 0 ? 1.5 : -6.5, // y position
          -10,
        ],
      };
    } else {
      return skill;
    }
  });

  // Adjust camera settings for mobile
  const cameraProps = isMobile
    ? { position: [0, 0, 15], fov: 60 }
    : { position: [0, 0, 11], fov: 50 };

  return (
    <SkillsSection id="skills">
      <h2 className="heading">
        My <span>Skills</span>
      </h2>

      <StyledCanvas camera={cameraProps}>
        <ambientLight intensity={0.6} />
        <directionalLight position={[10, 10, 10]} />

        {/* Moving stars with theme-based color */}
        <MovingStars theme={theme} />

        {/* Display skill icons inside the stars */}
        {adjustedSkills.map((skill, index) => (
          <SkillIcon
            key={index}
            icon={skill.icon}
            title={skill.title}
            position={skill.position}
            highlighted={expandedSkillIndex === index}
            subskills={skill.subskills}
            isExpanded={isMobile || expandedSkillIndex === index} // Always expanded on mobile
            onClick={() => !isMobile && setExpandedSkillIndex(index)} // Clickable only on desktop
          />
        ))}

        {/* Orbit controls for zoom (disabled on mobile) */}
        {!isMobile && <OrbitControls enableZoom={true} />}
      </StyledCanvas>
    </SkillsSection>
  );
}

export default Skills;