import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Typewriter } from 'react-simple-typewriter';
import ThreeCanvas from './ThreeCanvas';
import { FaLinkedin, FaGithub, FaTwitter } from 'react-icons/fa';
import MohamedResume from '../assets/pdfs/Mohamed_Abdelnaby_Resume.pdf';
import MohamedCV from '../assets/pdfs/Mohamed_Abdelnaby_CV.pdf';


const HomeSection = styled.section`
  display: flex;
  align-items: center;
  padding: 100px 50px;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.sectionBg1};

  .home-content {
    flex: 1;
    color: ${({ theme }) => theme.text};
    max-width: 600px;

    h1 {
      font-size: 48px;
      margin-bottom: 20px;
      font-weight: bold;
      position: relative;
      display: inline-block;

      span {
        background: linear-gradient(90deg, #8352FD, #FF6FD8);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -5px;
        width: 100%;
        height: 4px;
        background-color: #8352FD;
        animation: slideIn 1s ease forwards;
      }
    }

    h3 {
      font-size: 28px;
      margin-bottom: 20px;
      color: #8352FD;
      font-weight: 500;
    }

    p {
      font-size: 18px;
      line-height: 1.8;
      margin-bottom: 30px;
      letter-spacing: 0.5px;
    }

    .social-buttons-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 30px;

      @media (max-width: 768px) {
        align-items: center;
      }
    }

    .social-media {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      a {
        font-size: 24px;
        margin-right: 15px;
        color: ${({ theme }) => theme.text};
        transition: color 0.3s;

        &:hover {
          color: #8352FD;
        }
      }
    }

    .btn {
      padding: 12px 25px;
      margin-right: 15px;
      background-color: #8352FD;
      color: #FFFFFF;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #6C3CE6;
      }
    }

    .button-group {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-top: 10px;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center; /* Center buttons on smaller screens */
      }

      @media (max-width: 480px) {
        .btn {
          width: 100%; /* Make the buttons full width on very small screens */
          text-align: center; 
        }
      }
    }
  }

  .threejs-container {
    flex: 1;
    height: 500px;

    @media (max-width: 1024px) {
      height: 400px;
    }

    @media (max-width: 768px) {
      height: 350px;
    }

    @media (max-width: 480px) {
      height: 300px;
    }

    @media (max-width: 360px) {
      height: 250px;
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;

    .home-content {
      text-align: center;
      padding: 0 20px;
    }

    h1 {
      font-size: 36px;
    }

    h3 {
      font-size: 24px;
    }

    p {
      font-size: 16px;
    }

    .btn {
      padding: 10px 20px;
    }

    .threejs-container {
      width: 100%;
      height: 300px;
      margin-top: 50px;
    }
  }

  @media (max-width: 768px) {
    .home-content {
      h1 {
        font-size: 32px;
      }

      h3 {
        font-size: 22px;
      }

      p {
        font-size: 14px;
      }

      .btn {
        padding: 8px 18px;
      }
    }

    .threejs-container {
      height: 750px;
    }
  }

  @keyframes slideIn {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
`;

function Home() {
  return (
    <HomeSection id="home">
      <motion.div
        className="home-content"
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
      >
        <h1>
          Hi, I'm <span>Mohamed Abdelnaby</span>
        </h1>
        <motion.h3
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.8 }}
        >
          I'm a{' '}
          <span style={{ color: '#8352FD' }}>
            <Typewriter
              words={['Software Engineer', 'Web Developer', 'Machine Learning Researcher']}
              loop={0}
              cursor
              cursorStyle="_"
              typeSpeed={70}
              deleteSpeed={50}
              delaySpeed={1000}
            />
          </span>
        </motion.h3>
        <motion.p
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4, duration: 0.8 }}
        >
          Currently pursuing an accelerated Master of Science in Computer Science at the University of Oklahoma.
        </motion.p>

        <motion.div
          className="social-buttons-container"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6, duration: 0.8 }}
        >
          <div className="social-media">
            <a href="https://www.linkedin.com/in/mabdelnaby1/" target="_blank" rel="noopener noreferrer">
              <FaLinkedin />
            </a>
            <a href="https://github.com/MohamedHAbdelnaby" target="_blank" rel="noopener noreferrer">
              <FaGithub />
            </a>
            <a href="https://twitter.com/MABD214" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </a>
          </div>

          <div className="button-group">
            <a href={MohamedResume} className="btn" target="_blank" rel="noopener noreferrer">
              Resume
            </a>
            <a href={MohamedCV} className="btn" target="_blank" rel="noopener noreferrer">
              CV
            </a>
            <a href="#contact" className="btn">Contact Me</a>
          </div>
        </motion.div>
      </motion.div>

      <motion.div
        className="threejs-container"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1, duration: 1 }}
      >
        <ThreeCanvas />
      </motion.div>
    </HomeSection>
  );
}

export default Home;