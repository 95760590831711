export const lightTheme = {
    body: '#FDFDFD', 
    text: '#2C2C2C', 
    scrollbar: '#B0B0B0', 
    card: '#FFFFFF', 
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)', 
  
    sectionBg1: '#E8F0F2', 
    sectionBg2: '#F1F5F9',
  };
  
  export const darkTheme = {
    body: '#121212',
    text: '#FFFFFF',
    scrollbar: '#6B6B6B',
    card: '#1E1E1E',
    boxShadow: '0 4px 6px rgba(255, 255, 255, 0.1)',
    sectionBg1: '#1A1A1A', 
    sectionBg2: '#2E2E2E', 
  };